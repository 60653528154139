import { template as template_80a37839a0b44818aa671a4df44e79b9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_80a37839a0b44818aa671a4df44e79b9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
