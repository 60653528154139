import { template as template_495d6e7f0ed847cd9da760ce4a07cd3f } from "@ember/template-compiler";
const FKLabel = template_495d6e7f0ed847cd9da760ce4a07cd3f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
